.button {
  @include font($font_bold);
  border-radius: 30px;
  height: 60px;
  max-width: 336px;
  width: 100%;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in-out;

  &:focus {
    outline: none;
  }

  &[disabled]{
    background-color: $bg13 ;
    pointer-events: none;
  }

  .loader {
    max-width: 20px;
    max-height: 20px;
    border-width: 4px;
  }
}

.button_main {
  color: $white;
  background-color: $bg_btn;
  box-shadow: none;

  &:hover {
    background-color: $bg_btn_hover;
    box-shadow: 0 4px 20px 0 #90C13B80;
  }

  &:active {
    background-color: $bg_btn_active;
    box-shadow: 0 0 10px 0 #90C13B80;
  }
}

.button_delete-data {
  background-color: $bg_btn_del;

  &:hover {
    background-color: $bg_btn_hover_del;
    box-shadow: 0 0 20px 0 $btn_shadow_del;
  }

  &:active {
    background-color: $bg_btn_hover_del;
    box-shadow: 0 0 10px 0 $btn_shadow_del;
  }
}

.button_border {
  background-color: $white;
  border: 1px solid $bg5;
  color: $bg;

  &:hover {
    border-color: $text6;
    color: $text6;
  }

  &:active {
    color: $text7;
    border-color: $text7;
  }
}

.button_white {
  color: $bg;
  background-color: $white;

  &:hover {
    color: $text6;
  }

  &:active {
    color: $text7;
  }
}

.button_icon {
  column-gap: 15px;
}

.button__text {
  display: block;
}

.button_edit {
  position: absolute;
  bottom: 40px;
  right: 30px;
  width: 153px;
  height: 40px;
  padding-left: 16px;
  padding-right: 14px;
  font-size: 14px;
  line-height: 17px;
}

.button_save{
  width: 109px;
  height: 40px;
  font-size: 14px;
  margin: 28px 3px 0 auto;
}

@media screen and (max-width: 1320px) {

  .button_icon {
    padding-left: 15px;
    font-size: 14px;
    line-height: 18px;
  }

  .button_main {
    padding-left: 10px;
    padding-right: 10px;
  }

  .button_edit{
    bottom: 85px;
    height: 50px;
  }
}
