@font-face {
  font-family: "Lato";
  src: url(../fonts/Lato-Regular.woff2) format("woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url(../fonts/Lato-Bold.woff2) format("woff2");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@mixin font($font: $font_regular) {
  //@if $font == Lato-light {
  //  font-family: "Lato", sans-serif;
  //  font-weight: 300;
  //  font-style: normal;
  //}
  //
  //@if $font == Lato-light-i {
  //  font-family: "Lato", sans-serif;
  //  font-weight: 300;
  //  font-style: italic;
  //}
  //
  @if $font == Lato-regular {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  //@if $font == Lato {
  //  font-family: "Lato", sans-serif;
  //  font-weight: 500;
  //  font-style: normal;
  //}

  //@if $font == Lato-semibold {
  //  font-family: "Lato", sans-serif;
  //  font-weight: 600;
  //  font-style: normal;
  //}
  //
  @if $font == Lato-bold {
    font-family: "lato", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  //
  //@if $font == Lato-bold-i {
  //  font-family: "lato", sans-serif;
  //  font-weight: 700;
  //  font-style: italic;
  //}
  //
  //@if $font == Lato-black {
  //  font-family: "lato", sans-serif;
  //  font-weight: 900;
  //  font-style: normal;
  //}
  //
  //@if $font == Lato-black-i {
  //  font-family: "lato", sans-serif;
  //  font-weight: 900;
  //  font-style: italic;
  //}
}