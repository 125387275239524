
.contacts {
  grid-area: contacts;
  background-color: $bg4;
  border-radius: 20px;
  padding: 28px 30px 36px;
  display: grid;
  grid-template-areas:
          'c_title c_contacts'
          'c_btn   c_contacts';
  grid-template-columns: 48% 1fr;
  column-gap: 30px;
  color: $white;
}

.contacts_none {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 30px;

  .contacts__title {
    text-align: center;
  }
}

.contacts__title {
  grid-area: c_title;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 23px;
}

.contacts__subtitle {
  font-size: 16px;
  line-height: 19px;
  display: block;
  margin-top: 3px;
}

.contacts__button {
  grid-area: c_btn;
  font-size: 18px;
  line-height: 21px;
  max-width: 260px;
}

.contacts__wrapper {
  grid-area: c_contacts;
}

.contacts__inner {
  display: flex;
  column-gap: 30px;
}

.contacts__info {
  @include font($font_bold);
  padding-top: 13px;
  margin-bottom: 4px;
  color: $text8;
}

.contacts__image {
  border-radius: 50%;
  overflow: hidden;
  height: max-content;
}

.contacts__name {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 12px;
}

.contacts__status {
  padding-left: 18px;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: calc(50% - 5px);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $error_border;
  }

  &.active {

    &::before {
      background-color: $bg5;
    }
  }
}

@media screen and (max-width: 1320px) {

  .contacts__button {
    font-size: 16px;
    line-height: 18px;
  }
}
