// common style
@import 'styles/vars';
@import 'styles/mixin';
@import 'styles/reset';
@import 'styles/fonts';
@import 'styles/common';
@import 'styles/form';
@import 'styles/sidebar';
@import 'styles/custom-scroll';
@import 'styles/modals';

// Shared
@import '~shared/ui/Button/style';
@import '~shared/ui/Loader/style';
@import '~shared/ui/Search/style';
@import '~shared/ui/Contacts/style';

// Components
@import '~components/Authorization/style';
@import '~components/Logout/style';
@import '~components/Graph/style';
@import '~components/Avatar/style';
@import '~components/ReportModal/style';
@import '~components/ConfirmModal/style';
@import '~components/Logo/style';

// Pages
@import '~pages/Home/style';
@import '~pages/Tariff/style';
@import '~pages/Sites/style';
@import '~pages/Revenue/style';
@import '~pages/Dashboard/style';
@import '~pages/Reports/style';
@import '~pages/EditReports/style';
@import '~pages/Clients/style';
@import '~pages/ErrorPage/style';
@import '~pages/ReportPage/style';
@import '~pages/ProfilePage/style';


