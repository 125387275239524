.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid $bg5;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}