// главный цвет
$common_color: #313746;
$white: #fff;
$text: #313746;
$text2: #71798d;
$text3: #737b8d;
$text4: #c5cad4;
$text5: #78a32e;
$text6: #597b1f;
$text7: #476218;
$text8: #99a4bb;
$text9: #a3abbd;
$text10: #d13939;

$error_color: #d13939;
$error_border: #d13939;
$error_bg: #faf1f1;

$success_color: #90c13b;
$success_border: #90c13b;
$success_bg: #fff;

$color-1: #d3d4d6;
$color-2: #e7e9eb;

$bg: #78a32e;
$bg2: #f1f3f5;
$bg3: #dbdbdb;
$bg4: #3e4043;
$bg5: #90c13b;
$bg6: #f7f8fb;
$bg7: #c4d5a8;
$bg8: #e3f1cb;
$bg9: #d9d9d9;
$bg10: #b7db77;
$bg11: #407cb8;
$bg12: #dfe2ea;
$bg13: #e86969;

$bg_btn: #90c13b;
$bg_btn_hover: #9fd73d;
$bg_btn_active: #b6e663;

$bg_btn_del: #d13939;
$bg_btn_hover_del: #ec3434;
$btn_shadow_del: #d1393980;

$btn_shadow: #90c13b80;
$shadow: #47484a33;

$font_regular: Lato-regular;
$font_bold: Lato-bold;

//Разрешения экранов
$desktop-width: 1320px;
$laptop-width: 1024px;
$tablet-width: 768px;
$mobile-width: 320px;
