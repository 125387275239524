.sidebar__wrapper {
  margin-right: 10px;
}

.sidebar__container {
  position: sticky;
  top: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 82px);
  min-height: 825px;
}

.sidebar__user-link {
  display: block;
  margin: 0 auto 14px;
  width: fit-content;
}

.sidebar__user {
  @include font($font_regular);
  font-size: 24px;
  line-height: 29px;
  color: $text;
  text-align: center;
  margin-bottom: 29px;
  overflow: hidden;
}

.sidebar__user_cut {
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 48px;
    height: 100%;
    background-image: linear-gradient(
      270deg,
      #f1f3f5 0%,
      rgba(241, 243, 245, 0) 100%
    );
    position: absolute;
    right: 0;
    top: 0;
  }
}

.sidebar__li {
  @include font($font_bold);
  color: $text3;
  font-size: 14px;
  line-height: 16px;
  transition: color 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    color: $text;
  }

  &.is-active {
    background-color: $white;
    border-radius: 10px;
    color: $text;

    .sidebar__li-title {

      &::before {
        background-color: $text;
      }
    }
  }
}

.sidebar__li-title {
  position: relative;
  display: block;
  margin-left: 15px;
  padding-top: 9px;
  padding-bottom: 10px;
  padding-left: 27px;

  &::before {
    position: absolute;
    content: "";
    width: 13px;
    height: 14px;
    left: 0;
    top: calc(50% - 7px);
    mask-image: url(~img/sidebar/1.svg);
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: $text3;
    transition: background-color 0.2s ease-in-out;
  }

  &:hover {

    &::before {
      background-color: $text;
    }
  }

  &.tab-2 {

    &::before {
      mask-image: url(~img/sidebar/2.svg);
    }
  }

  &.tab-3 {

    &::before {
      mask-image: url(~img/sidebar/3.svg);
    }
  }

  &.tab-4 {

    &::before {
      mask-image: url(~img/sidebar/4.svg);
    }
  }

  &.tab-5 {

    &::before {
      mask-image: url(~img/sidebar/5.svg);
    }
  }

  &.title_more {
    display: none;

    &::before {
      mask-image: url(~img/sidebar/6.svg);
    }
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.content__wrapper {
  grid-column: 2/3;
  background-color: $white;
  border-radius: 20px;
  margin-top: 16px;
  margin-right: 19px;
}

.content__wrapper_loader {
  display: flex;
  min-height: 500px;
}

.sidebar__help {
  margin-top: auto;
  color: $text3;
  margin-left: 10px;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: $text;
  }
}

.sidebar__copyright {
  color: $text3;
  margin-left: 10px;
  margin-top: auto;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: $text;
  }
}

.sidebar__profile-wrapper {
  position: relative;
  padding-top: 26px;
  margin-top: 21px;
  margin-left: 6px;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 5px;
    right: 8px;
    height: 1px;
    background-color: $bg3;
  }

  &:hover {

    .sidebar__profile {
      color: $text;
    }

    .sidebar__profile-icon {
      border-color: $text;

      svg {

        path {
          stroke: $text;
        }
      }
    }
  }
}

.sidebar__profile {
  display: flex;
  align-items: center;
  color: $text3;
  margin-left: 4px;
  column-gap: 8px;
  transition: color 0.2s ease-in-out;
}

.sidebar__profile-icon {
  width: 40px;
  height: 40px;
  border: 1px solid $color-1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.3s ease-in-out;

  svg {

    path {
      transition: stroke 0.3s ease-in-out;
    }
  }
}

@media screen and (max-width: 767px) {

  .content__wrapper {
    grid-column: 1/2;
    margin-right: 0;
    margin-bottom: 24px;
  }

  .sidebar__user {
    font-size: 18px;
    max-width: unset;
    margin: unset;
    line-height: 20px;
    text-align: left;
  }

  .sidebar__profile-wrapper {
    margin: unset;
    padding: unset;

    &::before {
      display: none;
    }
  }

  .sidebar__profile {
    margin-left: unset;
  }

  .sidebar__wrapper_admin {
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    grid-template-rows: auto;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 7px;
    align-items: center;

    .logout__wrapper {
      grid-column: 3/4;
      margin: unset;

      .logout__text {
        display: none;
      }
    }

    .sidebar__user {
      max-width: 100%;
      margin-left: 12px;
      font-size: 16px;
      margin-right: 5px;
      grid-column: 2/3;
      grid-row: 1;
    }
  }

  .sidebar__container {
    position: static;
    display: grid;
    grid-template-columns: auto 40px;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 30px;
    min-height: 47px;
    padding-left: 15px;
    padding-right: 5px;
    height: unset;

    .logout__wrapper {
      display: none;
    }
  }

  .sidebar__logo-bg {
    font-size: 24px;
  }

  .sidebar__user-wrapper {
    grid-row: 1/4;
    align-items: center;
    display: flex;
  }

  .sidebar__li {
    font-size: 11px;

    &.is-active {
      background-color: unset;
    }
  }

  .sidebar__li-title {
    margin-left: unset;
    padding-top: 27px;
    padding-bottom: 0;
    padding-left: unset;

    &::before {
      left: calc(50% - 7px);
      top: 6px;
    }
  }

  .sidebar__nav-wrapper {
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    margin-top: 0;
    position: absolute;
    bottom: 0;
    z-index: 5;
    background-color: #f7f8fb;
    left: 0;
    right: 0;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 -0.5px 0 0 #d9d9d9;
  }

  .sidebar__nav {
    display: flex;

    > .sidebar__li,
    .sidebar__li-wrapper {
      width: 20%;
      min-width: 20%;
      height: 65px;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      > .sidebar__li-title,
      .title_more {
        width: 100%;
        justify-content: center;
        text-align: center;
      }
    }
  }

  .sidebar__help,
  .sidebar__copyright,
  .sidebar__profile-text {
    display: none;
  }

  .sidebar__li-wrapper_more {
    cursor: pointer;
    position: relative;

    .sidebar__li-title.title_more {
      display: flex;
      color: $text3;

      &:hover {
        color: $text;
      }
    }

    .sidebar__li-inner {
      display: none;
      position: absolute;
      bottom: 60px;
      right: 15px;
      width: 173px;
      border-radius: 10px;
      background: $white;
      box-shadow: 0 2px 15px 0 rgba(71, 72, 74, 0.2);

      &.active {
        display: block;
        padding-left: 9px;
        padding-right: 8px;
      }

      .sidebar__li {
        border-radius: 0;

        & + .sidebar__li {
          border-top: 1px solid $bg3;
        }
      }

      .sidebar__li-title {
        padding-top: 11.5px;
        padding-bottom: 11.5px;
        padding-left: 27px;

        &::before {
          left: 2px;
          top: calc(50% - 6px);
        }
      }
    }
  }

  .sidebar__profile-icon {

    svg {

      path {
        stroke: $text;
      }
    }
  }
}
