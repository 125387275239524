.form__group {
  display: flex;
  flex-direction: column;

  .label {
    @include font($font_bold);
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
    color: $text2;
  }

  .input {
    @include font($font_regular);
    background-color: $bg2;
    border-radius: 30px;
    height: 60px;
    max-width: 336px;
    width: 100%;
    font-size: 20px;
    line-height: 24px;
    box-sizing: border-box;
    padding-left: 20px;
    padding-bottom: 5px;
    border: 1px solid transparent;

    &.error {
      border-color: $error_border;
      background-color: $error_bg;
    }

    &::placeholder {
      color: $text4;
    }

    &:focus {
      outline: none;
    }
  }
}

.link {
  color: $text5;

  &.link_accent {

    &:hover {
      color: $text6;
    }

    &:active {
      color: $text7;
    }
  }
}

