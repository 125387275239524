@mixin laptop {
  @media screen and (max-width: $desktop-width) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: calc($laptop-width - 1px)) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: calc($tablet-width - 1px)) {
    @content;
  }
}
