.custom-scroll {

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: $bg6;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #CFD7E3;
    border-radius: 20px;
  }
}
