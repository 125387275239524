html {
  @include font($font_regular);
  font-size: 14px;
  color: $common_color;
  background-color: $bg2;
}

#root {
  height: 100%;
}

html, body {
  overflow-x: hidden;
  height: 100%;
}

input,
button {
  border: none;
}

.bold {
  @include font($font_bold);
}

.bold_grey {
  @include font($font_bold);
  color: $text3 !important;
}

.nobr {
  white-space: nowrap;
}

.status__block {
  display: block;
  max-width: fit-content;
  padding: 15px;
  color: $error_color;
  background-color: $error_bg;
  border: 1px solid $error_border;
  border-radius: 4px;
  margin: 0 0 10px;

  &.status__block_success {
    color: $success_color;
    background-color: $success_bg;
    border: 1px solid $success_border;
  }
}
