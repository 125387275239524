#modal-root {
  position: fixed;
  z-index: 1100;
}

.modal__overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal__content {
  position: relative;
  background: white;
  width: 500px;
  max-width: 80%;
  padding: 30px 30px 40px;
  border-radius: 30px;
}

.modal__close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  color: $text3;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.modal__title {
  @include font($font_bold);
  font-size: 24px;
  line-height: 28.8px;
  color: $text;
}

.modal__subtitle {
  @include font($font_bold);
  font-size: 14px;
  line-height: 14.4px;
  color: $text2;
}

.modal__textarea {
  height: 300px;
  max-height: 600px;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}

.modal-tariff {

  .modal__content {
    width: 470px;
    padding: 30px;
  }

  .label {
    margin-bottom: 10px;
  }

  .input {
    max-width: 191px;
    height: 36px;
    border-radius: 10px;
    background-color: $white;
    border: 1px solid $bg9;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
  }

  .input-postfix {
    font-size: 14px;
    line-height: 16px;
    margin-left: 13px;
  }

  .form__group_name {
    margin-bottom: 18px;
  }

  .label-checkbox {
    margin-bottom: 20px;
  }

  .modal__title {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 15px;
  }
}

.modal-success {

  .modal__content {
    width: unset;
  }

  .success-icon-wrapper {
    padding: 25px;
    max-width: 150px;
    border-radius: 50%;
    background-color: #90c13b;
    margin: auto;
    width: 100vw;
  }
}

.modal-mail {

  .modal__close {
    top: 12px;
    right: 11px;
    width: 34px;
    height: 34px;
  }

  .status-label {
    margin-left: 10px;
  }

  .button {
    margin-right: auto;
    width: 250px;
    line-height: 21px;
    font-size: 16px;
    height: 50px;
  }

  .button_save {
    margin: 0 auto;
  }

  .button_delete-data {
    background-color: $bg13;
  }

  .modal__text {
    margin-top: 15px;
  }

  .error {
    color: $error_color;
  }

  .success {
    color: $success_color;
  }

  .modal__content {
    padding-top: 48px;
    width: calc(100% - 30px);
    max-width: 330px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .modal__title {
    font-size: 22px;
    line-height: 26px;
    margin-right: 10px;
    margin-bottom: 32px;
  }

  .input {
    height: 42px;
    font-size: 14px;
    padding-left: 15px;
    padding-bottom: 0;
    border-radius: 10px;
    background-color: $white;
    border: 1px solid $bg9;
    max-width: 300px;
  }

  .modal__button-wrapper {
    flex-direction: column;
    margin-top: 32px;
    row-gap: 16px;
    max-width: 210px;
    margin-left: auto;

    .button {
      margin-left: unset;
      margin-right: unset;
      width: 100%;
    }

    &.refresh {
      max-width: unset;
    }
  }
}

.modal__button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  margin-top: 28px;
}

@media screen and (max-width: 767px) {

  .modal__content {
    max-width: calc(100% - 30px);
    padding: 20px 20px 40px;

    .button {
      font-size: 16px;
    }
  }

  .modal__close {
    top: 13px;
    right: 11px;
    width: 34px;
    height: 34px;
  }

  .modal-tariff {

    .modal__overlay {
      align-items: flex-start;
      padding-top: 30px;
      padding-bottom: 30px;
      overflow-y: scroll;
    }

    .modal__content {
      padding: 20px 20px 40px;
    }

    .modal__title {
      font-size: 22px;
      line-height: 26px;
      margin-top: 27px;
      margin-bottom: 33px;
    }

    .form__group_name {
      margin-bottom: 34px;
    }

    .input {
      padding-top: 5px;
      padding-left: 16px;
      height: 42px;
      max-width: unset;
      font-size: 14px;
    }

    .input-postfix {
      margin-left: -28px;
      margin-right: 17px;
    }

    .tariff-checkbox-wrapper {
      padding-left: 26px;

      label {
        line-height: 18px;
        padding-left: 0;

        &::before {
          left: -25px;
          top: 0;
        }
      }
    }

    .button_save {
      width: 150px;
      height: 50px;
      margin-top: 32px;
      margin-right: 0;
    }
  }
}
