.search {
  position: relative;
  width: fit-content;
}

.search-input {
  background-color: $bg6;
  border-radius: 20px;
  max-width: 223px;
  width: 100%;
  height: 40px;
  padding-left: 17px;
  padding-right: 30px;
  box-sizing: border-box;
}

.search-icon {
  position: absolute;
  top: calc(50% - 7px);
  right: 15px;
}

@include mobile {
  
  .clients__search {
    margin-left: auto;
    margin-right: auto;
  }
}